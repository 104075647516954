import { FC } from "react";
import Container from "../../components/footer/Container";
import Companies from "./Companies";
import Ideas from "./Ideas";
import Qestions from "./Questions";
import Special from "./Special";
import WhyMMT from "./WhyMMT";
import Slider from "./Slider";
import FaqForm from "../../components/FaqForm/FaqForm";
import Popular from "./Popular";
import Reviews from "./Reviews";
import Reviews2 from "./Reviews2";
// import PaymentMethods from "./PaymentMethods/PaymentMethods";

const MainPage:FC = () => {


    return(
        <>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                <Slider />
                <Ideas />
                <Special />
                <Reviews2 />
            </Container>
            </div>
            <Container>
                <Popular />
            </Container>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                <WhyMMT />
                <Reviews />
            </Container>
            </div>

            <Container>
                <Companies />
            </Container>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                <Qestions />
                <div style={{paddingBottom: '160px'}}>
                    <div className={'about_faq'}>
                    <FaqForm/>
                    </div>
                </div>
                {/* <PaymentMethods /> */}
            </Container>
            
            </div>


        </>
    )
}

export default MainPage;