import { FC, useEffect, useState } from "react";
import { Collapse, CollapseProps } from "antd";
import './CatalogFilters.scss'
import { useMediaQuery } from "../../hooks/useMediaQuery";
import closeImg from './close.svg'

interface IProps{
  filters: any
  selectedFilters: any
  setSelectedFilters: any
  setCatalogProductsData: any
  fetchData: any,
  openFilters: boolean,
  searchData: string | null
  handleClose: () => void
}

const CatalogFilters:FC<IProps> = (props) => {

  const {handleClose, openFilters, filters, selectedFilters, setSelectedFilters, setCatalogProductsData, fetchData, searchData } = props


    const [price, setPrice] = useState({
        minPrice: 0,
        maxPrice: 999999999,
    });
    
    const handleApplyFilters = () => {
    const filtersParams = {
      colors: selectedFilters.colors.join(),
      materials: selectedFilters.materials.join(),
      custom_types: selectedFilters.custom_types.join(),
      types:  selectedFilters.types.join(),
      minPrice: price.minPrice,
      maxPrice: price.maxPrice,
      search: searchData
    }
    setCatalogProductsData([])
    fetchData( 0, 0, filtersParams )
  }

  const handleResetFilters = () => {
    window.location.reload();
  };


  const handleSelectFilters = (id: number, filterType: string) => {
    if (filterType === "color") {
      if (selectedFilters.colors.includes(id)) {
        const updatedArray = selectedFilters.colors.filter(
          (color: any) => color !== id
        );
        setSelectedFilters({ ...selectedFilters, colors: updatedArray });
        return;
      }
      setSelectedFilters({
        ...selectedFilters,
        colors: [...selectedFilters.colors, id],
      });
      return;
    }
    if (filterType === "customTypes") {
      if (selectedFilters.custom_types.includes(id)) {
        const updatedArray = selectedFilters.custom_types.filter(
          (costom: any) => costom !== id
        );
        setSelectedFilters({ ...selectedFilters, custom_types: updatedArray });
        return;
      }
      setSelectedFilters({
        ...selectedFilters,
        custom_types: [...selectedFilters.custom_types, id],
      });
      return;
    }
    if (filterType === "types") {
      if (selectedFilters.types.includes(id)) {
        const updatedArray = selectedFilters.types.filter(
          (type: any) => type !== id
        );
        setSelectedFilters({ ...selectedFilters, types: updatedArray });
        return;
      }
      setSelectedFilters({
        ...selectedFilters,
        types: [...selectedFilters.types, id],
      });
      return;
    }
    if (filterType === "materials") {
      if (selectedFilters.materials.includes(id)) {
        const updatedArray = selectedFilters.materials.filter(
          (material: any) => material !== id
        );
        setSelectedFilters({ ...selectedFilters, materials: updatedArray });
        return;
      }
      setSelectedFilters({
        ...selectedFilters,
        materials: [...selectedFilters.materials, id],
      });
      return;
    }
  };


const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Өнім түрі',
    children: filters?.types?.map((item: any, i: number) => (
      <div key={i}>
        <input
          readOnly
          className="  h-4 w-4 border border-black rounded-md bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          value={item.id}
          id={item.name}
          checked={selectedFilters?.types.includes(item.id)}
          onClick={() =>
            handleSelectFilters(item.id, "types")
          }
        />
        <label
          className="form-check-label  text-gray-800 flex flex-col justify-center"
          htmlFor={item.name}
        >
          {item.name}
        </label>
      </div>
    )),
  },
  {
    key: '2',
    label: 'Бағасы',
    children: <div className="flex w-full§">
                  <div className="py-2  flex flex-col">
                    <div className="flex flex-row justify-around items-center ">
                      <div>
                        <span>Бастап</span>
                      </div>
                      <div>
                        <span>Бұрын</span>
                      </div>
                    </div>
                    <div className="flex flex-row justify-arounds">
                      <div>
                        <input
                          // readOnly
                          className=" flex text-center font-jost  border-black border-[1px] rounded-full ... w-20 h-8 px-2 py-1 text-black placeholder:text-stone-900"
                          placeholder="0"
                          type="number"
                          name="search"
                          onChange={(e) =>
                            setPrice({
                              ...price,
                              minPrice: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className="flex flex-row  items-center justify-center">
                        -
                      </div>
                      <div>
                        <input
                          // readOnly
                          className=" flex text-center font-jost  border-black border-[1px] rounded-full ... w-20 h-8 px-2 py-1 text-black placeholder:text-stone-900"
                          type="number"
                          name="search"
                          onChange={(e) =>
                            setPrice({
                              ...price,
                              maxPrice: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
  },
  {
    key: '3',
    label: 'Түс',
    children: filters?.colors?.map((item: any, i: number) => (
      <div key={i}>
        <input
          readOnly
          className="  h-4 w-4 border border-black rounded-md bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          value={item.id}
          id={item.name}
          checked={selectedFilters.colors.includes(item.id)}
          onClick={() =>
            handleSelectFilters(item.id, "color")
          }
        />
        <label
          className="form-check-label  text-gray-800 flex flex-col justify-center"
          htmlFor={item.name}
        >
          {item.name}
        </label>
      </div>
    )),
  },
  {
    key: '4',
    label: 'Материал',
    children: <div style={{maxWidth: '250px'}}>{filters?.materials?.map((item: any, i: number) => (
      <div key={i}>
        <input
          readOnly
          className="  h-4 w-4 border border-black rounded-md bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          value={item.id}
          id={item.name}
          checked={selectedFilters?.materials.includes(item.id)}
          onClick={() =>
            handleSelectFilters(item.id, "materials")
          }
        />
        <label
          className="form-check-label  text-gray-800 flex flex-col justify-center"
          htmlFor={item.name}
        >
          {item.name}
        </label>
      </div>
    ))}</div>
  },
  {
    key: '5',
    label: 'Қолдану әдісі',
    children: filters?.costom_types?.map((item: any, i: number) => (
      <div key={i}>
        <input
          readOnly
          className="  h-4 w-4 border border-black rounded-md bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          value={item.id}
          id={item.name}
          checked={selectedFilters?.custom_types.includes(item.id)}
          onClick={() =>
            handleSelectFilters(item.id, "customTypes")
          }
        />
        <label
          className="form-check-label  text-gray-800 flex flex-col justify-center"
          htmlFor={item.name}
        >
          {item.name}
        </label>
      </div>
    )),
  },
];
const isMobile = useMediaQuery('(max-width: 768px)')
useEffect(() => {
  const container = document.querySelector('#filters_container_page') as HTMLDivElement

  if (openFilters === true){
    document.body.style.overflow = 'hidden';
    container.style.overflow = 'hidden';
  }else {
    container.style.overflow = 'auto';

    document.body.style.overflow = 'auto';

  }
}, [openFilters])
    if (!openFilters && isMobile) return <></>
    return(
      <div className={isMobile && openFilters ? 'background' : ''}>
        <div
      className={`
      ${isMobile ? 'filters_mobile_wrapper' : ''}
        ${openFilters ? 'filters_open_wrapper': ''}
        `}
      >
        {
          isMobile
          &&
          <div className={'filters_mobile_top_modal'}>
            <p>Фильтр и сортировка</p>

            <div onClick={handleClose} className={'filters_mobile_close_modal'}>
              <img src={closeImg} alt={"close"} width={34} height={34}/>
            </div>
          </div>
        }
        <div className="customFilters">
        <Collapse className={`
        filtersWrapper 
        
        `} ghost items={items} expandIconPosition={'end'}/>
        </div>

        <div className="catalogFilters">
          <p className="catalogFiltersReset" onClick={() => handleResetFilters()}>Қалпына келтіру</p>
          <p className="catalogFiltersApply" onClick={() => {
            handleApplyFilters()
            if (isMobile) handleClose()
          }}>Көрсету</p>
        </div>
      </div>
      </div>
    );
};

export default CatalogFilters;