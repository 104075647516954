import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './PortfolioDetail.module.scss'
import { AxiosResponse } from 'axios';
import { PUBLIC_API } from '../../constants/api';
import Container from '../../components/footer/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import './navigation.css';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import FaqForm from '../../components/FaqForm/FaqForm';


const getDataPortfolio = async (id: string) => {
    try {
        const res: AxiosResponse<any> = await PUBLIC_API.get(`portfolio/${id}`)
        return res.data.data;
    } catch {
        return null
    }
}
const PortfolioDetail: FC = () => {
    const {id} = useParams();
    const [data, setData] = useState<any>();
    const isMobile = useMediaQuery('(max-width: 768px)')
    useEffect(() => {
        (async () => {
            const data = await getDataPortfolio(id || '');
            setData(data)
        })()
    }, [])
    
    return (
        <Container>
            <div className={classes.wrapper}>
            <div className={classes.images}>
                <div className={`${classes.mainImage} ${data?.images.length === 0 ? classes.empty : ''}`}>
                <Swiper
                    modules={[!isMobile ? Navigation : Pagination]}
                    navigation={!isMobile}
                    pagination={isMobile ? {clickable: true} : false}
                    height={isMobile ? 414 : 556}
                    className={classes.swiper}
                    spaceBetween={0}
                    slidesPerView={1}
                >
                    {/* <SwiperSlide className={classes.slide}>
                        <img className={classes.mainSourceImage} src={'https://suveniry-mmt.kz/' + data?.photo} alt={'main product'}/>
                    </SwiperSlide> */}
                    {
                        data?.images?.slice(2)?.map((item: any) => (
                            <SwiperSlide  className={classes.slide}><img className={classes.mainSourceImage} src={'https://suveniry-mmt.kz/' + item?.image} alt={'main product'}/></SwiperSlide>
                        ))
                    }
                </Swiper>
                    
                </div>
                {
                    data?.images?.length > 0
                    &&
                <div className={classes.secondaryWrapper}>
                    {
                        data?.images?.slice(0, 2)?.map((item: any) => (
                            <img className={classes.secondaryImage} src={'https://suveniry-mmt.kz/' + item?.image} alt={'main product'}/>
                        ))
                    }
                </div>
                }
            </div>

            <div className={classes.blocks}>
                <div className={classes.block1}>
                    <div className={classes.item}>
                        <p className={classes.headtitle}>Клиент</p>

                        <div className={classes.infoItem}>
                        {data?.client}
                        </div>
                    </div>  
                        <div className={classes.item}>
                        <p className={classes.headtitle}>Тапсырма</p>
                        <div className={classes.infoItem}>
                            {data?.task}
                        </div>
                    </div>

                </div>

                <div className={classes.block2}>
                    <div className={classes.item}>
                        <p className={classes.headtitle}>Шешім</p>

                        <div className={classes.infoItem}>
                        {data?.solution}
                        </div>
                    </div>
                    <div className={classes.item}>
                        <p className={classes.headtitle}>Оәлі де сұрақтар бар?</p>
                        <div className={'portfolio_faq'}>
                            <FaqForm showQuestion={true} showTitle={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Container>
    );
};
export default PortfolioDetail;