import { FC, useEffect, useState } from "react";
import { fetchCategories } from "../../store/category";
import { useAppDispatch } from "../../store";
import { useCategoryProducts } from "../../store/category/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import './Header.scss'
import { useMediaQuery } from "../../hooks/useMediaQuery";

interface IHeaderSecondary{
    setOpen: (open: boolean) => void,
    hadnleNavigate: (link: string) => void,
}

const HeaderSecondary:FC<IHeaderSecondary> = (props) => {

  const token = localStorage.getItem("token");

  const { setOpen, hadnleNavigate } = props

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();


  const [subcategories, setSubcategories] = useState([]);
  const [mobSub, setMobSub] = useState([])
  const [isSub, setIsSub] = useState(false)


  useEffect(() => {
    dispatch(fetchCategories() as any);
  }, [dispatch]);

  const categories = useCategoryProducts();


  const handleCategoryClick = (link: string, sub?: any) => {
   if(!isMobile){
    navigate(link)
    if (location.pathname === "/catalog") {
      setTimeout(() => window.location.reload(), 100);
    }
    setOpen(false)
  }
    else{ 
      setMobSub(sub)
      setIsSub(true)
    }
  } 

  const isMobile = useMediaQuery('(max-width: 768px)')

  const handleNavigate = (link: string) => {
    hadnleNavigate(link);
    setOpen(false);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

    return (
        <div 
            className="headerSecondaryWrapper"
            onMouseLeave={() => setOpen(false)}
        >
          <div className="headerSecondaryCategory">
            {!isSub ? <>{categories.length > 0 && categories?.map((category: any) => (
                <div 
                  className="headerSecondaryCategoryItem" 
                  key={category.name}
                  onClick={() => handleCategoryClick(`/catalog?categoryId=${category.id}`, category.subcategories)}
                  onMouseMove={() =>
                    setSubcategories(category.subcategories)
                  }
                  >
                    {category.name}
                </div>
            ))}</> : <>           
            {isMobile 
            && 
            <div>
              <p
                  className="headerSecondaryCategoryItem" 
                  onClick={() => setIsSub(false)}
              >Назад</p>
              {mobSub?.map((item: any) => (
                <div 
                onClick={() => {
                      navigate(`/catalog?categoryId=${item.id}`)
                      if (location.pathname === "/catalog") {
                        setTimeout(() => window.location.reload(), 100);
                      }
                      setOpen(false)
                      }}
                  className="headerSecondaryCategoryItem" 
                >{item.name}</div>
              ))}
            </div>
            }</>}
            

 

          </div>
          {
            !isMobile
            &&
          <>
          <div className="headerDivider"></div>
          <div className="headerSecondarSubcategory">
            {subcategories?.length > 0 &&
                subcategories.map((item: any) => (
                  <div 
                    key={item.id}
                  >
                    <div 
                      className="headerSecondarSubcategoryItem"
                      onClick={() => handleCategoryClick(`/catalog?categoryId=${item.id}`)}
                    >
                        {item.name}
                    </div>
                    {item.subcategories.length > 0 && item.subcategories.map((item: any) => (
                      <div 
                        className="headerSecondarSubSubcategoryItem"
                        onClick={() => handleCategoryClick(`/catalog?categoryId=${item.id}`)}
                      >
                        {item.name}
                    </div>
                    ))}
                  </div>
                ))}
          </div>
          </>
          }

          {
            isMobile
            &&
            <div>
              <div 
                    className="headerNavigation"
                >
                    {!token ? <div onClick={() => handleNavigate('/signup')}>Регистрация</div> :<div onClick={() => handleNavigate('/profile/')}>Профиль</div>}
                    {!token ? <div onClick={() => handleNavigate('/signin')}>Войти</div>: <></>}
                    <div onClick={() => handleNavigate('/about')}>Компания</div>
                    <div onClick={() => handleNavigate('/portfolio')}>Портфолио</div>
                    <div onClick={() => handleNavigate('/')}><a href="#faq">FAQ</a></div>
                    <div onClick={() => handleNavigate('/contacts')}>Байланыс</div>
                    <a href="tel:+996555883500">Позвонить</a>
                    <div onClick={() => handleNavigate('/blog')}>Блог</div>
                    <div onClick={() => handleNavigate('/lookbook')}>Lookbook</div>
                </div>
            </div>
          }
        </div>
        )
}

export default HeaderSecondary