import { FC, useEffect, useState } from "react";
import Container from "../../../components/footer/Container";
import { PUBLIC_API } from "../../../constants/api";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import '../../NewCatalog/Catalog.scss'
import '../Companies/Companies.scss'
import './Popular.css'
import { LeftOutlined, RightOutlined } from "@ant-design/icons";


const Popular:FC = () => {


const getPopular = async(): Promise<AxiosResponse<any> | null> => {
  try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/products/popular/");
      return res.data;
  }catch{
      return null
  }
}

const navigate = useNavigate()
const [popular, setPopular] = useState<any>()

useEffect(() => {
    (async () => {
    const data = await getPopular()
    setPopular(data)
    }
    )()
}, [])

const [pop, setPop] = useState<any>()

useEffect(() => {
    setPop(popular?.slice(0, 3))
}, [popular])

    return(
        <>
        {pop?.length ? 
            <div className="popWrapper">
                <Container>
                    <div className="popTitleButtons">
                        <p className="companiesTitle">MMT тобының танымал сыйлықтары</p>
                        {popular?.length > 5 && <div className="popButtons">
                            <div onClick={() => setPop(popular?.slice(0, 3))}><LeftOutlined rev={undefined} /></div>
                            <div onClick={() => setPop(popular?.slice(3, 6))}><RightOutlined rev={undefined} /></div>
                        </div>}
                    </div>
                    <div className="popularCards">
                        {pop && pop?.map((item: any) => (
                            <div className="catalogCardWrapper1" onClick={() => navigate(`/product/${item.id}`)}>
                                <div>   
                                    <img className="catalogCardImg" src={item?.colors[0]?.images[0]?.big} alt={item.name} />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    gap: '20px',
                                    marginBottom: '24px',
                                    }} 
                                    className="catalogCardInfo"> 
                                    <p style={{ fontSize: '20px', fontWeight: 600, width: 300}} className="catalogCardTitle">{item.name}</p>
                                    <p className="catalogCardPrice">{item.price} ₸</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        : <></>
        }
        </>
    )
}

export default Popular