import { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { useAppDispatch } from "../../../store";
import { getBanner } from "../../../store/varia";
import { useBanner } from "../../../store/varia/hooks";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import classes from './Slider.module.scss'
import '../../PortfolioDetail/navigation.css'
import { useNavigate } from "react-router-dom";
import { PUBLIC_API } from "../../../constants/api";
import { AxiosResponse } from "axios";
import one from './desk/1.png'
import two from './desk/2.png'
import three from './desk/3.png'
import four from './desk/4.png'
import five from './desk/5.png'
import six from './desk/6.png'
import mobOne from './mob/1.png'
import mobTwo from './mob/2.png'
import mobThree from './mob/3.png'
import mobTour from './mob/4.png'
import mobFive from './mob/5.png'
import mobSix from './mob/6.png'


const Slider:FC = () => {

  const isMobile = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getBanner() as any);
  }, [dispatch])

  const banner = useBanner()
  console.log(banner)

const getPresentation = async(): Promise<AxiosResponse<any> | null> => {
  try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/presentation/");
      return res.data;
  }catch{
      return null
  }
}
const [presentation, setPresentation] = useState<any>()

useEffect(() => {
    (async () => {
    const data = await getPresentation()
    setPresentation(data)
    console.log(presentation, 'presentation')

    }
    )()
}, [])

useEffect(() => {
    console.log(presentation, 'presentation')
}, [presentation])


    return (
        <div className={classes.main}>
            <Swiper 
                className={classes.mainSlider}   
                modules={[Navigation, Pagination]}
                navigation={true}
                pagination={{clickable: true}}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
            >

                {banner?.length &&  
                    banner.map((item: any) => (    
                        !isMobile ? 
                        <SwiperSlide>
                            <div className={classes.slide} style={{backgroundImage: `url(https://suveniry-mmt.kz${item.banner})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}> 
                                <div className={classes.info}>
                                    <p className={classes.title2}>{item.title}</p>
                                    <p className={classes.descr2}>{item.description}</p>
                                    <div className={classes.actionButtons2}>
                                        <div><a href={`${item.link}`}>Жаңа каталог элементтері</a></div>
                                        <div><a href={item.presentation} target="_blank" rel="noreferrer">Презентацияны жүктеп алу</a></div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                            :                             
                <SwiperSlide>
                    <div className={classes.mobSlide3} style={{backgroundImage: `url(https://suveniry-mmt.kz${item.mob_banner})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                        <p style={{color: '#fff'}}>{item.title}</p>
                        <p style={{color: '#fff'}}>{item.description}</p>
                        </div>
                        <div className={classes.mobButton1}><a href={`${item.link}`}>Жаңа каталог элементтері</a></div>
                    </div>
                </SwiperSlide>              
 
                    ))
                }
                {/* {!isMobile ? 
                <>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${one})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title1}>Все, чем пользуется ваш клиент, дома, на работе, в пути может быть с вашим логотипом</p>
                                        <p className={classes.descr1}>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                                        <div className={classes.actionButtons1}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${two})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title2}>Ищете нечто особенное для своего бизнеса?</p>
                                        <p className={classes.descr2}>Мы создаем корпоративный мерч, который заставит ваш бренд выделиться!</p>
                                        <div className={classes.actionButtons2}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${three})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title3}>Разместите логотип вашей компании или незабываемый принт на самых крутом мерче и деловых подарках</p>
                                        <p className={classes.descr2}>В MMT group есть все, что нужно, чтобы произвести сильное впечатление на сотрудников, клиентов и бизнес-партнеров.</p>
                                        <div className={classes.actionButtons2}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${four})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title4}>Гордимся каждой ручкой, футболкой и блокнотом, которые создаем для вас.</p>
                                        <p className={classes.descr2}>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                                        <div className={classes.actionButtons2}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${five})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title4}>Все, чем пользуется ваш клиент, дома, на работе, в пути может быть с вашим логотипом</p>
                                        <p className={classes.descr2}>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                                        <div className={classes.actionButtons2}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                    <SwiperSlide>
                                <div className={classes.slide} style={{backgroundImage: `url(${six})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                                    <div className={classes.info}>
                                        <p className={classes.title5}>Гордимся каждой ручкой, футболкой и блокнотом, которые создаем для вас.</p>
                                        <p className={classes.descr3}>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                                        <div className={classes.actionButtons1}>
                                            <div onClick={() => navigate('/catalog')}>Новинки каталога</div>
                                            <div><a href={presentation?.link ? presentation?.link : 'https://suveniry-mmt.kz' + presentation?.presentation} target="_blank" rel="noreferrer">Скачать презентацию</a></div>
                                        </div>
                                    </div>
                                </div>
                    </SwiperSlide>
                </>
                :
                <>
                <SwiperSlide>
                    <div className={classes.mobSlide1} style={{backgroundImage: `url(${mobOne})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <p>Все, чем пользуется ваш клиент, дома, на работе, в пути может быть с вашим логотипом</p>
                        <p className={classes.descr1}>Мы создаем корпоративный мерч, который заставит ваш бренд выделиться!</p>
                        <div onClick={() => navigate('/catalog')} className={classes.mobButton2}>Новинки каталога</div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.mobSlide2} style={{backgroundImage: `url(${mobTwo})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                            <p>Ищете нечто особенное для своего бизнеса?</p>
                            <p>Мы создаем корпоративный мерч, который заставит ваш бренд выделиться!</p>
                        </div>
                        <div  onClick={() => navigate('/catalog')} className={classes.mobButton1}>Новинки каталога</div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.mobSlide3} style={{backgroundImage: `url(${mobThree})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                            <p>Ищете нечто особенное для своего бизнеса?</p>
                            <p>Мы создаем корпоративный мерч, который заставит ваш бренд выделиться!</p>
                        </div>
                        <div onClick={() => navigate('/catalog')} className={classes.mobButton1}>Новинки каталога</div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.mobSlide3} style={{backgroundImage: `url(${mobTour})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                            <p>Гордимся каждой ручкой, футболкой и блокнотом, которые создаем для вас.</p>
                            <p>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                        </div>
                        <div onClick={() => navigate('/catalog')} className={classes.mobButton1}>Новинки каталога</div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.mobSlide3} style={{backgroundImage: `url(${mobFive})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                            <p>Все, чем пользуется ваш клиент, дома, на работе, в пути может быть с вашим логотипом</p>
                            <p>Уникальный дизайн, качественные материалы и безупречное исполнение - все это воплощается в наших продуктах.</p>
                        </div>
                        <div onClick={() => navigate('/catalog')} className={classes.mobButton1}>Новинки каталога</div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.mobSlide3} style={{backgroundImage: `url(${mobSix})`,  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div>
                            <p style={{color: '#000'}}>Гордимся каждой ручкой, футболкой и блокнотом, которые создаем для вас.</p>
                            <p style={{color: '#000'}}>Мы создаем корпоративный мерч, который заставит ваш бренд выделиться!</p>
                        </div>
                        <div onClick={() => navigate('/catalog')} className={classes.mobButton1}>Новинки каталога</div>

                    </div>
                </SwiperSlide>
                </>
                } */}
            </Swiper>
        </div>
    )
}

export default Slider