import { FC } from "react";
import './WhyMMT.scss'
import one from './6.svg'
import two from './7.svg'
import three from './Depth.svg'
import four from './Portal.svg'
import five from './Group 89.svg'
import six from './Smile.svg'

interface IWhyMMTItemProps{
    icon: string
    title: string
    description: string
}

const WhyMMTItem:FC<IWhyMMTItemProps> = (props) => {

    const {icon, title, description} = props

    return (
        <div className="whyMMTItem">
            <img src={icon} alt="icon"/>
            <p className="whyMMTTitle">{title}</p>
            <p className="whyMMTDescr">{description}</p>
        </div>
    )
}

const WhyMMT:FC = () => {

    const whyArr = [
        {
            icon: five,
            title: 'Көпжылдық тәжірибе',
            description: 'Корпоративтік мерчандайзинг өкілетінде сәтті жұмыс істеу 10 жылдан астам уақыттың өтініші, біздің көмектілігіміз мен сенімділігімізді растайды.',
        },
        {
            icon: two,
            title: "Үлкен брендтермен жұмыс",
            description: "Біздің елдегі басқау компаниялар мен брендтермен ынтымақтастық, биздің жұмысымыздың рынға берілетін жоғары бағалауын растайды."
        },
        {
            icon: one,
            title: "Сапа және стиль",
            description: "Материалдар мен өнімділіктің жоғары сапасы, біздің мерчандағымыздың сенімділігі мен ұзаққайырлылығын кепілдейтін."
        },
        {
            icon: four,
            title: "Клиенттерге арналған әділ құрал",
            description: "Біз сізді және сіздің қажеттілеріңізді басқа бірінші орынға көмеге шақырамыз, жеңіл шарттар мен жеке жеңілдіктер ұсынамыз."            
        },
        {
            icon: six,
            title: "1000+ ұмтылған клиенттер",
            description: "Материалдар мен өнімділіктің жоғары сапасы, біздің мерчандағымыздың сенімділігі мен ұзаққайырлылығын кепілдейтін."
        },
        {
            icon: three,
            title: "Брендтеу үшін 2800+ өнім",
            description: "Материалдар мен өнімділіктің жоғары сапасы, біздің мерчандағымыздың сенімділігі мен ұзаққайырлылығын кепілдейтін."
        }
    ]

    return(
        <div className="whyWrapper">
            <div className="whyTitleWrapper">
                <p className="whyTitle">Брендтер неге MMT тобын таңдайды</p>
            </div>
            <div className="whyMMTBlock">
                {whyArr.map((item) => (
                    <WhyMMTItem 
                        key={item.title}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </div>
        </div>
    )
}


export default WhyMMT;