import { FC, useEffect } from "react";
import './Companies.scss';
import { useAppDispatch } from "../../../store";
import { getBrands } from "../../../store/varia";
import { useBrands } from "../../../store/varia/hooks";

const Companies:FC = () => {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getBrands() as any);
    }, [dispatch]);
    const brands = useBrands()


    return(
        <div className="companiesWrapper">
            <p className="companiesTitle">Біз кеңес <br/> беретін компаниялар</p>
            <div className="companeisBlock">
                {brands?.map((item) => (
                    <div className="companiesItem">
                        <img src={item.logotype} alt={item.name} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Companies;