import { FC } from 'react';
import Container from '../../components/footer/Container';
import classes from './index.module.scss'
import womanImage from '../../assets/womanBruh.png';
import zoom from '../../assets/icons/Zoom.png';
import googleMeet from '../../assets/icons/googleMeet.png';
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import { ToastContainer, toast } from 'react-toastify';
import { PUBLIC_API } from '../../constants/api';

const Index: FC = () => {
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const date = values?.date_of_visit?.format('YYYY-MM-DD HH:mm')
        const backData = {
            ...newValues,
            date_of_visit: date,
            type_of_appli: '3',
        }
        try {
            const res = await PUBLIC_API.post('partners/application/', backData)
            if (res.data.status === 'CREATED'){
                toast.success('Өтініш жіберілді')
            }
        } catch (error) {
            toast.error('Қателік пайда болды')
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error('Кестелерді толтырыңыз!')
    }
    return (
       <>
        <Container className={classes.container}>
            <div className={classes.imageBlock}>
                <div>
                    <h1 className={classes.headTitle}>
                    ВИДЕОКЕҢЕС
                    </h1>
                    <div className={classes.items}>
                        <div className={classes.item}>1. Сіздің таңдаған сувенирлерді видеоаралық арқылы көрсетеміз</div>
                        <div className={classes.item}>2. Шоу-румды онлайн экскурсия өткіземіз</div>
                        <div className={classes.item}>3. Логотип астындағы жаңалықтар мен сыйлықтармен таныссақ</div>
                        <div className={classes.item}>4. Сізге ұнатқан мерчандайздың видеошолуын түсіресіз</div>
                    </div>
                    <div className={classes.reducer02}>
                        <p>Байланыс форматтары:</p>
                        <div className={classes.icons}>
                            <img src={zoom} alt=""/>
                            <img src={googleMeet} alt=""/>
                        </div>
                    </div>
                </div>
                <img src={womanImage} alt=""/>
            </div>

            <div className={classes.formWrapper}>
                <h1 className={classes.headTitle}>КЕҢЕС АЛУ</h1>
                <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className={classes.form}
                >
                    <Form.Item
                    rules={[{ required: true, message: 'Атыңызды енгізіңіз!' }]}
                    name={'name'}
                    >
                        <Input className={classes.input} placeholder={'Аты'}/>
                    </Form.Item>
                    <Form.Item
                    rules={[{ required: true, message: 'Компанияның атауын енгізіңіз!' }]}


                    name={'company'}
                    >
                        <Input className={classes.input} placeholder={'Компания'}/>
                    </Form.Item>

                    <Form.Item
                    rules={[{ required: true, message: 'Email деректерін енгізіңіз!' }]}


                    name={'email'}
                    >
                        <Input className={classes.input} placeholder={'Корпоративтік e-mail'}/>
                    </Form.Item>
                    <Form.Item
                    rules={[{ required: true, message: 'Байланыс типін таңдаңыз!' }]}


                    name={'type_of_video'}
                    >
                        <Select
                        className={classes.select}
                        options={[
                            { value: '1', label: 'Zoom' },
                            { value: '2', label: 'Google Meet' },
                            
                        ]}
                        placeholder={'Сізге ыңғайлы байланыс форматын таңдаңыз'}
                        />
                    </Form.Item>
                    <Form.Item
                    name={'comment'}
                    >
                        <Input.TextArea className={classes.input} placeholder={'Хабарлама жазыңыз'}/>
                    </Form.Item>
                    <div className={classes.date}>
                        <p>Сіз үшін ыңғайлы кеңес күні мен уақытын таңдаңыз.</p>
                        <Form.Item
                    rules={[{ required: true, message: 'Келу уақытын енгізіңіз!' }]}


                        name={'date_of_visit'}
                        >
                            <DatePicker dropdownClassName={classes.dropdown} format={'YYYY-MM-DD HH:mm'} className={classes.datePicker} locale={locale} showTime={{ format: 'HH:mm' }} placeholder={'10.11.2023'}/>
                        </Form.Item>
                    </div>
                    
                    <div className={classes.bottom}>
                        <Form.Item>
                            <Button className={classes.submit} type="primary" htmlType="submit">
                            Кеңес алу
                            </Button>
                        </Form.Item>
                        <Form.Item
                        name={'politika'}
                        valuePropName="checked"
                    rules={[{ required: true, message: 'Саясатпен келісім беріңіз' }]}

                    > 
                            <Checkbox>
                            Жеке деректерді өңдеу туралы келісім:
                            Сіздің контакттар деректеріңізді қалдыру арқылы сіз шексіздік бересіз
                            жеке деректерді өңдеуге.
                            </Checkbox>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Container>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
    );
};
export default Index;