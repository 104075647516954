import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';

import trashBlack from "./assets/trashBlack.png";
// import defaultAvatar from "./assets/2222.png";
// import defaultAvatar from "./Ellipse 179.svg";
import defaultAvatar from './Group 222.png'
import { useNavigate } from "react-router-dom";
import API from "../../constants/api";

import nice from "./assets/nice.png";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import 'react-toastify/dist/ReactToastify.css';

interface ImageObj {
  blobUrl: string;
  file: File;
}

const ProfileComponent = () => {
  const [valuee, setValue] = useState("");
  const [errorText, setErrorText] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [images, setImages] = React.useState<ImageObj[]>([]);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    avatar: "",
    name: "",
    surname: "",
    phone: "+7",
    city: "",
    address: "",
    company: "",
    email: "",
  });
  const notify = () => {
    toast.success('Coxранено', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const [successfully, setSuccessfully] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSelectImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const max = 10;
      const files = Array.from(e.target.files || []).slice(0, max);

      setImages(
        files.map((file) => ({
          file,
          blobUrl: URL.createObjectURL(new Blob([file])),
        }))
      );
    },
    []
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorText([]);
    const avatar = new FormData();
    images?.length > 0 && avatar.append("avatar", images[0].file);
    if (!inputs.avatar) {
      avatar.append("avatar", "");
    }

    avatar.append("first_name", inputs.name);
    avatar.append("last_name", inputs.surname);
    avatar.append("phone", inputs.phone);
    avatar.append("city", inputs.city);
    avatar.append("address", inputs.address);
    avatar.append("company", inputs.company);
    avatar.append("email", inputs.email);
    try {
      const res = await (isEdit
        ? API.put("/user/profile", avatar, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        : API.post("/user/profile", avatar, {
          headers: { "Content-Type": "multipart/form-data" },
        }));
    } catch (e) {
      // @ts-ignore
      setErrorText([Object.entries(e.response.data)]);
    }
  };

  useEffect(() => {
    API.get("/user/profile")
      .then((res) => {
        setInputs({
          name: res.data.data.first_name,
          surname: res.data.data.last_name,
          phone: res.data.data.phone,
          city: res.data.data.city,
          address: res.data.data.address,
          company: res.data.data.company,
          email: res.data.data.email,
          avatar: res.data.data.avatar ? res.data.data.avatar : defaultAvatar,
        });
        setIsEdit(true);
        setValue(res.data.data.phone);
      })
      .catch((e) => setIsEdit(false));
  }, []);

  useEffect(() => {
    if (isEdit) {
      navigate("/profile?isEdit=true");
      return;
    }
    navigate("/profile?isEdit=false");
  }, [isEdit]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <div className="flex justify-center   w-auto md:w-32 lg:w-48 ">
        <form onSubmit={handleSubmit} className="lg:w-[45%] sm:w-96!  profileInputs">
          <div className="flex justify-between py-5 flex-col md:flex-col md:items-center ">
            <div className=" flex justify-center items-center">
              {images?.length ? (
                <img
                  src={images[0].blobUrl}
                  alt=""
                  className="w-32 h-32 flex justify-center rounded-full object-cover"
                />
              ) : (
                <img
                  src={inputs.avatar ? inputs.avatar : defaultAvatar}
                  alt=""
                  className="h-32 w-32 object-cover rounded-full"
                />
              )}
            </div>
            <div className="py-3 flex flex-col justify-center gap-2 w-auto text-sm  md:!flex-col">
              <div className="  text-[#79B15E] rounded-full ... flex items-center justify-center py-1 px-8 ">
                <input
                  onChange={(e) => onSelectImage(e)}
                  accept="image/*"
                  type="file"
                  className="hidden border border-black"
                  id="input_file"
                />
                <span
                  onClick={() => document.getElementById("input_file")?.click()}
                  className="flex justify-center items-center cursor-pointer font-normal"
                >
                  Фотосуретті өзгерту
                </span>
              </div>

              <div
                onClick={() => {
                  setImages([]);
                  setInputs({ ...inputs, avatar: "" });
                }}
                className="rounded-full ... gap-2 cursor-pointer  border-black flex text-[#CF1B17] items-center justify-center py-1 px-8"
              >
                {/* <img src={trashBlack} alt="" /> */}
                <span className=" md:flex">Жою</span>
              </div>
            </div>
          </div>
          <label className="block">
            <input
              placeholder="Аты"
              type="text"
              name="name"
              value={inputs.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid"
            />
            <input
              value={inputs.surname}
              placeholder="Тегі"
              name="surname"
              onChange={handleChange}
              type="text"
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid"
            />

            {/* <div className="w-full" >
              <PhoneInput
                value={valuee}
                onChange={(e: string) => {
                  setValue(e);
                }}
                country={"kg"}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  onchange: { handleChange },
                }}
                inputClass="transparent"
              />
            </div> */}
            
            <input
              value={inputs.phone}
              placeholder="+7"
              name="phone"
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid"
              />
            <input
              value={inputs.email}
              placeholder="email"
              type="email"
              name="email"
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid mb-[20px]"
              />

            <span className="text-black font-bold flex justify-center mb-[20px]">
              Қала
            </span>
            <input
              value={inputs.city}
              placeholder="Қала"
              type="text"
              name="city"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid" 
              />
            <input
              value={inputs.address}
              placeholder="Мекенжай"
              type="text"
              name="address"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid"
              />
            <input
              value={inputs.company}
              placeholder="Компания"
              type="text"
              name="company"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border-[1px] border-[#848484]  text-sm  font-light placeholder-[#848484]
              rounded-[12px] ... focus:outline-none border-solid mb-[40px]"
            />
          </label>

          {successfully ? (
            <>
              <div className="bg-[#343434] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="bg-[#343434] h-1"></div>

                <div className="flex justify-center items-center flex-col gap-5">
                  <label htmlFor=""></label>
                  <img src={nice} alt="" />
                  <p className="text-white">Сіз сәтті тіркелдіңіз</p>
                  <button
                    onClick={() => navigate("/")}
                    className="focus:outline-none rounded-full bg-white px-3 py-2  flex justify-center items-center"
                  >
                    Дайын
                  </button>
                </div>
                <div className="bg-white h-1"></div>
              </div>
            </>
          ) : null}

          <div className="text-red-800 ">
            {
              //@ts-ignore
              errorText?.length ? errorText[0].map((err) => err[1]) : null
            }
          </div>
          <button
            type="submit"
            className="bg-[#1F1F1F] rounded-full ...  flex justify-center w-[100%] py-2 text-white gap-2"
            onClick={notify}
          >
            {isEdit ? "Өзгерту" : "Сақтау"}
          </button>

        </form>
      </div>

    </>
  );
};

export default ProfileComponent;
