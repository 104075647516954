import { FC } from 'react';
import classes from './FaqForm.module.scss';
import { Button, Checkbox, Form, Input } from 'antd';
import { PUBLIC_API } from '../../constants/api';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const FaqForm: FC<{showTitle?: boolean, showQuestion?: boolean}> = (props) => {
    const {showTitle=true, showQuestion=false} = props;

    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const backData = {
            ...newValues,
        }
        try {
            const res = await PUBLIC_API.post('partners/application-call/', backData)
            if (res.data.status === 'CREATED'){
                toast.success('Өтінім жіберілді')
            }
        } catch (error) {
            toast.error('Қате орын алды')
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error('Өрістерді толтырыңыз!')
    }
    const isMobile = useMediaQuery('(max-width: 768px)')
    return (
        <>
        <Form
            form={form}
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed} 
        >
            {
                showTitle
                &&
                <h1 className={classes.headTitle}>Табыстап алуға дайынмын! Сұрақтарыңызды қосымша беріңіз</h1>

            }
            <div className={classes.inputs}>
                <Form.Item rules={[{ required: true, message: 'Атыңызды енгізіңіз!' }]} name={'name'}>
                    <Input className={classes.input} placeholder={'Аты'}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Телефон нөмірін енгізіңіз!' }]} name={'phone_number'}>
                    <Input className={classes.input} placeholder={'+7'}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Электронды поштаны енгізіңіз!' }]} name={'email'}>
                    <Input className={classes.input} placeholder={'Email'}/>
                </Form.Item>
                
                {
                !isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                    Сұрақ қою
                </Button>
            </Form.Item>
            }
            </div>
            {
                showQuestion
                &&
                <div>
                    <Form.Item name={'question'}>
                        <Input.TextArea className={classes.input} placeholder={'Сұрақ'}></Input.TextArea>
                    </Form.Item>
                </div>
            }
            <div className={classes.bottom}>
            <Form.Item
                name={'politika'}
                valuePropName="checked"
                rules={[{ required: true, message: 'Саясатпен келісіңіз' }]}
            >
                <Checkbox>
                Жеке деректерді өңдеу туралы келісім:
                Өз контакттарыңызды қалдырғанда, сіз жеке деректерді өңдеу туралы келісім бересіз
                </Checkbox>
            </Form.Item>
            {
                isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                    Сұрақ қою
                </Button>
            </Form.Item>
            }
                
            </div>
            
        </Form>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
        
    );
};
export default FaqForm;